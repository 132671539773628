
.Loader {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left:0;
  right:0;
  width: 100%;
  height: 100%;
  text-align: center;
}