* {
  margin:0;
  padding:0;
  box-sizing: border-box;
}
html,body,#root{
  height: 100%;
  width: 100%;
}
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
body {
    font-family: 'Open Sans', sans-serif;
    color: #03355E;
    box-sizing: border-box;
    font-size: 14px;
    line-height: 16px;
    user-select: none;
}
h1, h2, h3, h4, h5, h7 {
    font-family: 'Proza Libre', sans-serif;
    color: #03355E;
    line-height: 24px;
    margin: 0 0 8px 0;
    font-weight: 700;
}

h1 { font-size: 22px; }
h2 { font-size: 20px; }
h3 { font-size: 18px; }


/* Color Theme Swatches in Hex */
.My-Color-Theme-1-hex { color: #2EC4B6; }
.My-Color-Theme-2-hex { color: #E71D36; }
.My-Color-Theme-3-hex { color: #EFFFE9; }
.My-Color-Theme-4-hex { color: #011627; }
.My-Color-Theme-5-hex { color: #03355E; }




@import url('https://fonts.googleapis.com/css?family=Open+Sans|Proza+Libre:700');
